import { Grid, Divider as _Divider } from '@mui/material'
import { styled } from '@mui/system'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { Layout } from '../../components/Layout'
import { RingBaseSelect } from '../../components/RingBaseSelect'
import { SEO } from '../../components/SEO'
import { Txt } from '../../components/Txt'

export const Head = () => (
    <SEO
        title="オリジナルのアレンジを加えたオーダーメイドリング一例 | 結婚指輪CAD-platinum"
        description="カスタマイズしたリングに石留めやミル打ち、艶消しなどお好みのアレンジを加えてオーダーメイド。世界でひとつのオリジナルリングのアレンジ事例をご紹介。理想と出会えるCAD-platinumで、一生に一度の記念品にふさわしい結婚指輪をお求め下さい。"
        page="semiorder/arrange"
    />
)

const RECOMMENDS = [
    {
        point: '地金アレンジ',
        name: 'CPTHR2LD3PL アレンジリング',
        price: 'PT950 & K18YG 78,000円（税込）',
        effects: [
            'ゴールドの鮮やかさと高級感が加わり立体感のある仕上がりになりました。',
        ],
        beforeImage: (
            <StaticImage src="../../images/arrange/ar1-1.jpg" alt="" />
        ),
        afterImage: <StaticImage src="../../images/arrange/ar1-2.jpg" alt="" />,
    },
    {
        point: 'ミル打ちアレンジ',
        name: 'CPTHR3LD3MLS アレンジリング',
        price: 'プラチナ950 68,500円（税込）',
        effects: [
            ' 4:1の位置に入ることでミルラインが一層際立ちダイヤモンドの存在感がよりスタイリッシュに。',
            '飽きのこない、オリジナル感のあるデザインに仕上がりました。',
        ],
        beforeImage: (
            <StaticImage src="../../images/arrange/ar2-1.jpg" alt="" />
        ),
        afterImage: <StaticImage src="../../images/arrange/ar2-2.jpg" alt="" />,
    },
    {
        point: '石留めアレンジ',
        name: 'CPTKM2SLD9PL アレンジリング',
        price: 'プラチナ950 75,300円（税込）',
        effects: [
            '花嫁の幸せを願う「サムシングブルー」',
            '艶やかなプラチナ輝きと絶妙にマッチする天然ブルーダイヤを4ピースセット。',
        ],
        beforeImage: (
            <StaticImage src="../../images/arrange/ar3-1.jpg" alt="" />
        ),
        afterImage: <StaticImage src="../../images/arrange/ar3-2.jpg" alt="" />,
    },
    {
        point: '形状アレンジ',
        name: 'CPGWC2.3D7PL アレンジリング',
        price: 'K18ピンクゴールド 83,000円（税込）',
        effects: [
            '美や喜びの象徴として人気の蝶をさりげなく一カ所だけ加えるのが、上品で可愛らしい仕上がりのポイント。',
        ],
        beforeImage: (
            <StaticImage src="../../images/arrange/ar4-1.jpg" alt="" />
        ),
        afterImage: <StaticImage src="../../images/arrange/ar4-2.jpg" alt="" />,
    },
    {
        point: '地金アレンジ',
        name: 'CYGKM2LD3PL アレンジリング',
        price: 'PT950 & K18YG 67,500円（税込）',
        effects: [
            '１本でゴールドとプラチナを使い分けられるコンビリング。プラチナ側にはダイヤを３pcセット',
        ],
        beforeImage: (
            <StaticImage src="../../images/arrange/ar5-1.jpg" alt="" />
        ),
        afterImage: <StaticImage src="../../images/arrange/ar5-2.jpg" alt="" />,
    },
    {
        point: '槌目アレンジ',
        name: 'CPGKM2.5LD1PL アレンジリング',
        price: 'K18ピンクゴールド 83,500円（税込）',
        effects: [
            '無数の反射面が動きや角度によってキラキラと輝き、槌目模様の重厚感も加わり上品な存在感のリングに仕上がりました。',
        ],
        beforeImage: (
            <StaticImage src="../../images/arrange/ar6-1.jpg" alt="" />
        ),
        afterImage: <StaticImage src="../../images/arrange/ar6-2.jpg" alt="" />,
    },
]

const ArrangePage: React.FC = () => {
    return (
        <Layout
            breadcrumbs={[
                { name: 'セミオーダー', path: 'semiorder' },
                { name: 'アレンジについて' },
            ]}
        >
            <Grid container spacing={0}>
                <Grid item sm={2} />
                <Grid item sm={8} xs={12}>
                    <CatchWrapper>
                        <CatchTxt>カスタマイズリングに</CatchTxt>
                        <CatchTxt>独自のアレンジを加えれば</CatchTxt>
                        <CatchTxt>世界に一つのオリジナルリングが完成</CatchTxt>
                    </CatchWrapper>
                    <TitleWrapper>
                        <TitleTxt size="l">人気のアレンジオーダー</TitleTxt>
                    </TitleWrapper>
                    <RecommendArrangeWrapper>
                        <RecommendArrange>
                            {RECOMMENDS.map((recommend, index) => (
                                <React.Fragment key={index}>
                                    {!!index && <Divider />}
                                    <ArrangePointWrapper>
                                        <ArrangePointTxt>
                                            {recommend.point}
                                        </ArrangePointTxt>
                                    </ArrangePointWrapper>
                                    {recommend.beforeImage}
                                    <EffectWrapper>
                                        {recommend.effects.map(
                                            (effect, index) => (
                                                <EffectTxt key={index}>
                                                    {effect}
                                                </EffectTxt>
                                            )
                                        )}
                                    </EffectWrapper>
                                    {recommend.afterImage}
                                    <Txt>{recommend.name}</Txt>
                                    <Txt>{recommend.price}</Txt>
                                </React.Fragment>
                            ))}
                        </RecommendArrange>
                    </RecommendArrangeWrapper>
                    <OtherArrangeWrapper>
                        <Txt size="l">arrange order</Txt>
                        <Txt>その他のデザイン</Txt>
                        <OtherArrageDescription>
                            <Txt>
                                その他の加工や形状など、掲載されていないデザインをご希望の場合もお気軽にお問い合わせ下さい。
                            </Txt>
                            <Txt>
                                お見積りと併せ、リングの画像をメールでご案内させて頂きます。
                            </Txt>
                        </OtherArrageDescription>
                        <OrderLink1 to="/fullorder/catalog/">
                            <Txt>その他アレンジ事例</Txt>
                        </OrderLink1>
                        <OrderLink2 to="/trying-on/">
                            <Txt>アレンジオーダーの流れ</Txt>
                        </OrderLink2>
                    </OtherArrangeWrapper>
                    <RingBaseSelect marginTop="52px" hideCatchTxt={true} />
                </Grid>
            </Grid>
        </Layout>
    )
}

const Divider = styled(_Divider)({
    margin: '40px 0',
})
const CatchWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '16px 0',
})
const CatchTxt = styled(Txt)({
    marginBottom: '8px',
})
const TitleWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#8d6449',
    padding: '4px',
})
const TitleTxt = styled(Txt)({
    color: '#ffffff',
})
const RecommendArrangeWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})
const RecommendArrange = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    padding: '20px 0',
})
const ArrangePointWrapper = styled('div')({
    margin: '32px 0',
})
const ArrangePointTxt = styled(Txt)({
    backgroundColor: '#ff5252',
    color: '#ffffff',
    borderRadius: '4px',
    padding: '4px 8px',
})
const EffectWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '12px',
})
const EffectTxt = styled(Txt)({
    width: '90%',
})
const OtherArrangeWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '52px',
})
const OtherArrageDescription = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    margin: '24px 12px',
    padding: '20px',
    backgroundColor: '#cfd8dc',
})
const OrderLink = styled(Link)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '30px',
    padding: '12px 20px',
    textDecoration: 'none',
    marginTop: '20px',
    width: '220px',
})
const OrderLink1 = styled(OrderLink)({
    backgroundColor: '#d3d3d3',
    color: '#000',
})
const OrderLink2 = styled(OrderLink)({
    backgroundColor: '#000000',
    color: '#fff',
})

export default ArrangePage
